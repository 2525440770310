import { useMutate } from "../../hooks/useMutations";
import { useNavigate, useParams } from "react-router-dom";
import { AUTH_API_URL } from "../../config/api";
import { useEffect } from "react";
import { HTTPStatusResponse } from "../../config/global";
import { toast } from "react-toastify";
import FullLoaderImg from "../../components/loader/FullLoaderImg";

export default function EmailVerification() {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { code } = useParams();

  /**
   * Mutations
   */
  const { mutateAsync } = useMutate(
    AUTH_API_URL + "auth/check-token"
  );

  /**
   * Functions
   */
  const emailVerification = () => {
    mutateAsync({ code: code })
      .then((response: any) => {
        if (response?.data?.status === HTTPStatusResponse.OK) {
          navigate("/reset-password", {
            state: { code: response.data.data },
          });
        } else {
          toast.error("Token invalide");
          navigate("/404", { replace: true });
        }
      })
      .catch((e) => {
        navigate("/500", { replace: true });
      });
  };

  /**
   * Effect
   */
  useEffect(() => {
    emailVerification();
  }, []);

  /**
   * UI
   */
  return <FullLoaderImg text="Vérification du lien"  showImage={false}/>;
}
