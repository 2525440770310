import { useMutate } from "../../hooks/useMutations";
import { useNavigate, useParams } from "react-router-dom";
import { AUTH_API_URL } from "../../config/api";
import { useEffect, useState } from "react";
import { HTTPStatusResponse } from "../../config/global";
import { toast } from "react-toastify";
import { BiMailSend } from "react-icons/bi";
import FullLoaderImg from "../../components/loader/FullLoaderImg";
import AuthHeader from "./AuthHeader";

export default function ConfirmationEmailPage() {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { code } = useParams();
  const [isSuccess, setIsSuccess] = useState(false);

  /**
   * Mutations
   */
  const { mutateAsync, isLoading } = useMutate(AUTH_API_URL + "auth/verify");

  /**
   * Functions
   */
  const confirmEmail = () => {
    setIsSuccess(false);
    mutateAsync({ code: code })
      .then((response: any) => {
        if (response?.data?.status === HTTPStatusResponse.OK) {
          setIsSuccess(true);
        } else {
          if (response?.data?.status === HTTPStatusResponse.ERROR) {
            toast.error("Token invalide!");
          }
          navigate("/400");
        }
      })
      .catch((e) => {
        navigate("/500", { replace: true });
      });
  };

  /**
   * Effect
   */
  useEffect(() => {
    confirmEmail();
  }, []);

  /**
   * UI
   */
  return (
    <>
      {isLoading && <FullLoaderImg text="Confirmation de l'adresse email" showImage={false} />}
      {isSuccess && (
        <div className="h-full">
          <div className="card">
            <AuthHeader title="Confirmation de l'adresse email" />
            <div className="card-body">
              <div className="p-2">
                <div className="text-center">
                  <div className="avatar-md mx-auto">
                    <div className="avatar-title rounded-full bg-light">
                      <BiMailSend size={"30px"} className="text-primary" />
                    </div>
                  </div>
                  <div className="p-2 mt-4">
                    <h4 className="text-xl">Success !</h4>
                    <p className="text-muted">
                      Vous venez de confirmer votre addresse email, votre compte
                      vient d'être activé vous permettant ainsi de profiter de
                      toutes les fonctionnalités de la plateforme
                    </p>
                    <div className="mt-4">
                      <a href="/sign-in" className="underline text-primary">
                        Connectez-vous
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
