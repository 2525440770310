import { Link, useNavigate } from "react-router-dom";
import { AUTH_API_URL } from "../../config/api";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../components/button/CustomButton";
import { InputEmail, InputText } from "../../components/form/Input";
import { Form } from "../../components/form/Form";
import { useMutate } from "../../hooks/useMutations";
import logo from "../../assets/images/logo.png";
import LoaderBarWrapper from "../../components/loader/LoaderBarWrapper";
import { useState } from "react";
import AuthHeader from "./AuthHeader";
import { HTTPStatusResponse } from "../../config/global";
import { toast } from "react-toastify";

export default function ForgetPassword() {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  const [emialIsSent, setEmailIsSent] = useState(false);
  const [usePhone, setUsePhone] = useState(false);

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  /**
   * Mutation
   */
  const { mutateAsync, isLoading } = useMutate(
    AUTH_API_URL + `auth/password-reset-mail`
  );

  /**
   * Functions
   */
  const handleSubmitForm = (datas: any) => {
    let result = mutateAsync(datas);
    result.then((response: any) => {
      if (response.data?.status === HTTPStatusResponse.OK) {
        if (usePhone) {
          handleGoToVerifyCodePage();
        } else {
          setEmailIsSent(true);
        }
      } else {
        toast.error(
          "Aucun compte n'a été trouvé à partir des informations fournie"
        );
      }
    });
  };

  const handleGoToVerifyCodePage = () => {
    navigate("/verify-code", {
      state: getValues(),
    });
  };

  /**
   * UI
   */
  return (
    <>
      <div className="card shadow">
        <LoaderBarWrapper loading={isLoading}>
          <AuthHeader title="Mot de passe oublié!" />
          <div className="card-body pt-0">
            <div className="auth-logo sm:mt-[-20px] sm:justify-start grid mt-[-10px] justify-center">
              <Link to="/" className="auth-logo-dark">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title bg-gray-100 rounded-[50%] bg-light">
                    <img src={logo} alt="Logo Nexah" className=" h-8 w-8" />
                  </span>
                </div>
              </Link>
            </div>
            {!emialIsSent ? (
              <div className="p-2">
                <Form
                  loading={isLoading}
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  <div className="mb-3">
                    <InputEmail
                      placeholder={"Entrer votre email"}
                      label={"Email"}
                      labelClassName={"form-label inline-block"}
                      register={register}
                      name={"email"}
                      error={errors.email}
                    />
                  </div>
                  {usePhone && (
                    <div className="mb-3">
                      <InputText
                        placeholder={"Entrer votre numéro de téléphone"}
                        label={"Téléphone"}
                        labelClassName={"form-label inline-block"}
                        register={register}
                        name={"phone"}
                        error={errors.phone}
                      />
                    </div>
                  )}
                  <hr className="mt-4 mb-4" />
                  <div>
                    <SubmitButton
                      value={"Récupérer"}
                      loading={isLoading}
                      className="mx-auto w-[140px]"
                    />
                  </div>
                </Form>
                <div className="mt-4 text-center">
                  <button
                    className="text-muted"
                    onClick={() => setUsePhone(!usePhone)}
                  >
                    {" "}
                    {usePhone
                      ? "Je souhaite utiliser uniquement mon adresse mail!"
                      : "Je souhaite ajouter mon numéro de téléphone!"}
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h1 className="text-3xl font-bold text-center mb-5 text-primary">
                  Success!
                </h1>
                <p className="text-center text-2xl mb-2">
                  Un mail a été envoyé à l'adresse email{" "}
                  <b>{getValues().email}</b>, consulter votre boîte mail pour
                  finaliser la récupération de votre compte.
                </p>
              </div>
            )}
          </div>
        </LoaderBarWrapper>
      </div>
      <div className="mt-5 text-center">
        <div>
          <p>
            Vous vous en souvenez ?
            <Link to="/sign-in" className="fw-medium text-primary ml-2">
              Connectez-vous
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
