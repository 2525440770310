import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @ts-ignore
import { AddButton, EditButton } from "../components/button/CustomButton";
// @ts-ignore
import CardTable from "../components/card/CardTable";
// @ts-ignore
import { InputPassword, InputText } from "../components/form/Input";
// @ts-ignore
import ModalForm from "../components/modal/ModalForm";
import { FormActionType, HTTPStatusResponse } from "../config/global";
// @ts-ignore
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
// @ts-ignore
import { ItemDropdownInterface } from "../components/dropdown/typings";
import { useEffect, useState } from "react";
// @ts-ignore
import Dropdown from "../components/dropdown/Dropdown";
// @ts-ignore
import { Select2 } from "../components/form/Select";

export default function UserPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    actionForm,
    setActionForm,
    titleForm,
    setTitleForm,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "user", title: "Utilisateurs" });
  const [openUpdatePasswordModal, setOpenUpdatePasswordModal] = useState(false);

  /**
   * Form
   */
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const {
    register: registerUpdatePassword,
    reset: resetUpdatePassword,
    handleSubmit: handleSubmitUpdatePassword,
    formState: { errors: errorsUpdatePassword },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?pageNo=${pagination.page}&pageSize=${pagination.perPage}`,
    queryKey
  );

  const { data: roles, refetch: refetchRole } = useQueries(
    APP_API_URL + `role-list`,
    ["role-list"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: updateData, isLoading: updateIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-update/${currentElement}`,
      "post",
      queryKey
    );

  const { mutateAsync: updatePassword, isLoading: updatePasswordIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}/resetpwd`,
      "post",
      queryKey
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      ...values,
      roleId: values.roleId.id,
    }).then((response: any) => {
      if (response && response.data.status === "OK") {
        reset();
        toast.success("Utilisateur ajouté !");
        setOpenModal(false);
      }
    });
  };

  const handleSubmitEditForm = (values: any) => {
    updateData(values).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        handleCancelForm();
        toast.success("Utilisateur modifié");
      }
    });
  };

  const handleSubmitUpdatePasswordForm = (values: any) => {
    updatePassword({ password: values.password }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        handleCancelForm();
        toast.success("Mot de passe mis à jour");
      }
    });
  };

  const handleCancelForm = () => {
    reset();
    resetUpdatePassword();
    setOpenModal(false);
    setOpenUpdatePasswordModal(false);
    setCurrentElement(0);
  };

  const handleAddElement = () => {
    setTitleForm("Ajouter un utilisateur");
    setActionForm(FormActionType.ADD);
    setOpenModal(true);
    reset();
  };

  const handleEditElement = (element: any) => {
    setTitleForm("Modifier un utilisateur");
    setActionForm(FormActionType.EDIT);
    setValue("firstName", element.firstName);
    setValue("lastName", element.lastName);
    setValue("email", element.email);
    setValue("phone", element.phone);
    setCurrentElement(element.uuid);
    setOpenModal(true);
  };

  const handleUpdatePasswordElement = () => {
    resetUpdatePassword();
    setOpenUpdatePasswordModal(true);
  };

  /**
   * Effect
   */
  useEffect(() => {
    refetchRole();
  }, []);

  /**
   * Users Dropdown options
   */
  const usersItems: ItemDropdownInterface[] = [
    {
      onClick: () => handleUpdatePasswordElement(),
      className: "",
      children: () => <span>Modifier le mot de passe</span>,
    },
  ];

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Nom",
      key: "firstName",
      dataIndex: "firstName",
    },
    {
      title: "Prénom",
      key: "lastName",
      dataIndex: "lastName",
    },
    {
      title: "Téléphone",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Nom d'utilisateur",
      key: "username",
      dataIndex: "username",
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          <EditButton onClick={() => handleEditElement(element)} value={""} />
          <Dropdown
            dataToItem={element}
            buttonChildren={"Options"}
            onOpen={() => setCurrentElement(element.uuid)}
            buttonClassName="bg-soft-primary"
            items={usersItems}
          />
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[<AddButton key={"add"} onClick={handleAddElement} />]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={
          actionForm === FormActionType.ADD
            ? storeDataIsLoading
            : updateIsLoading
        }
        onClose={handleCancelForm}
        onConfirm={handleSubmit(
          actionForm === FormActionType.ADD
            ? handleSubmitAddForm
            : handleSubmitEditForm
        )}
        open={openModal}
      >
        <div className={"grid grid-cols-2 gap-4"}>
          <div>
            <InputText
              placeholder={"Entrer le nom"}
              label={"Nom"}
              register={register}
              error={errors?.firstName}
              name={"firstName"}
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer le prénom"}
              label={"Prénom"}
              register={register}
              error={errors?.lastName}
              name={"lastName"}
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer l'adresse email"}
              label={"Email"}
              register={register}
              error={errors?.email}
              name={"email"}
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer le téléphone"}
              label={"Téléphone"}
              register={register}
              error={errors?.phone}
              name={"phone"}
            />
          </div>
          {actionForm === FormActionType.ADD && (
            <div className="col-span-2">
              <Select2
                items={roles?.data.data}
                control={control}
                placeholder={"Sélectionner le role"}
                label={"Role"}
                register={register}
                error={errors?.roleId}
                name={"roleId"}
                textKeyItem={"name"}
                valueKeyItem={"id"}
              />
            </div>
          )}
        </div>
      </ModalForm>

      <ModalForm
        title={"Mettre à jour le mot de passe"}
        isLoading={updatePasswordIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmitUpdatePassword(handleSubmitUpdatePasswordForm)}
        open={openUpdatePasswordModal}
      >
        <div>
          <InputPassword
            placeholder={"Entrer le nouveau mot de passe"}
            label={"Nouveau mot de passe"}
            register={registerUpdatePassword}
            error={errorsUpdatePassword?.password}
            name={"password"}
          />
        </div>
      </ModalForm>
    </>
  );
}
