import { Link, useLocation } from "react-router-dom";
import img from "../../assets/images/error-img.png";
import { LinkButton } from "../../components/button/CustomButton";
import { HOME_PAGE } from "../../config/global";
import { BiBuoy } from "react-icons/bi";

export default function Unautorized() {
  /**
   * Hooks
   */
  const { state } = useLocation();
  const message = state?.message
    ? state?.message
    : "Sorry, The requested resource is not available";

  /**
   * UI
   */
  return (
    <div className="account-pages my-5 pt-5">
      <div className="w-2/5 mx-auto">
        <div className="text-center mb-5">
          <h1 className="text-2xl lg:text-9xl md:text-4xl">
            4<BiBuoy className={"animate-spin text-primary"} style={{display:"initial"}} />3
          </h1>
          <h4 className="text-uppercase">{message}</h4>
          <div className="mt-5 text-center">
            <LinkButton className={"primary"}>
              <Link to={HOME_PAGE} className="primary">
                Back to home page
              </Link>
            </LinkButton>
          </div>
        </div>
        <div className="flex justify-center">
          <div>
            <img src={img} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
}
