import { createContext } from "react";
import { AuthContextInterface } from "./typinds";

const AuthContext = createContext<AuthContextInterface>({
    user: {
        user: { firstName: "", username: "", lastName: "", authorized: false, phone: "", email: "", orgId: 0, uuid: "" },
        roles: [{ id: 0, name: "" }]
    },
    signIn: (function (user: any, expireDate: string) { }),
    signOut: (function () { }),
});
export default AuthContext;