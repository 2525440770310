import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BadgeRounded } from "../../components/badge/Badge";
import { AddButton } from "../../components/button/CustomButton";
import CardTable from "../../components/card/CardTable";
import { Select2 } from "../../components/form/Select";
import ModalForm from "../../components/modal/ModalForm";
import { ColumnInterface } from "../../components/table/typings";
import { APP_API_URL } from "../../config/api";
import { HTTPStatusResponse, FormActionType } from "../../config/global";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { useMutateWithInvalidateQueries } from "../../hooks/useMutations";
import { useQueries } from "../../hooks/useQueries";
import { useParams } from "react-router-dom";

export default function SingleOrganizationAppPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    setActionForm,
    titleForm,
    setTitleForm,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "org-app", title: "Applications" });
  const params = useParams();
  const orgId = params.id;
  const queryKey = [
    `${baseApiUrl}s`,
    pagination.page,
    pagination.perPage,
    orgId,
  ];

  /**
   * Form
   */
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s/${orgId}?pageNo=${pagination.page}&pageSize=${pagination.perPage}`,
    queryKey
  );

  const { data: applications, refetch: refetchApplication } = useQueries(
    APP_API_URL + `application-list`,
    ["application-list"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      appsId: values.appsId.map((app: any) => app.id),
      orgId: orgId,
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        handleCancelForm();
        toast.success("Application ajoutée");
      }
    });
  };

  const handleAddElement = () => {
    setTitleForm("Ajouter une application");
    setActionForm(FormActionType.ADD);
    setCurrentElement(0);
    setOpenModal(true);
    reset();
  };

  const handleCancelForm = () => {
    reset();
    setCurrentElement(0);
    setOpenModal(false);
  };

  /**
   * Effect
   */
  useEffect(() => {
    refetchApplication();
  }, []);

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
    },
    {
      title: "Web URL",
      key: "appWebUrl",
      dataIndex: "appWebUrl",
    },
    {
      title: "Image",
      key: "logoUrl",
      render: (element: any) => (
        <img
          src={element.logoUrl}
          className="h-8 w-8 object-cover mx-auto"
          alt={element.name}
        />
      ),
    },
    {
      title: "Roles",
      key: "roles",
      render: (element: any) =>
        element.roles.map((role: any) => (
          <BadgeRounded
            key={role.id}
            text={role.name}
            className="mr-1 bg-secondary"
          />
        )),
    },
  ];

  //UI
  return (
    <>
      <CardTable
        extras={[
          <AddButton
            key={"add"}
            value={"Ajouter"}
            onClick={handleAddElement}
          />,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className={"grid gap-4"}>
          <div>
            <Select2
              multiple
              items={applications?.data.data}
              control={control}
              placeholder={"Sélectionner les applications"}
              label={"Applications"}
              register={register}
              error={errors?.appsId}
              name={"appsId"}
              textKeyItem={"name"}
              valueKeyItem={"id"}
            />
          </div>
        </div>
      </ModalForm>
    </>
  );
}
