import Card from "../card/Card";
import { CardSkeletonInterface } from "./typings";

export default function CardSkeleton({ numberOfCard, type }: CardSkeletonInterface) {
    const cards = [];
    for (let i: number = 0; i < numberOfCard; i++) {
        let simpleCard: JSX.Element;
        switch (type) {
            case "org":
                simpleCard = (
                    <Card title="" key={"card" + i}>
                        <div className="h-10 w-10 mx-auto mb-4 bg-soft-secondary animate-pulse"></div>
                        <div>
                            <div className="w-full bg-light animate-pulse h-5 mb-4"></div>
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                    <div className="h-2 mb-2 bg-light animate-pulse"></div>
                                    <div className="h-2 mb-2 bg-light animate-pulse"></div>
                                    <div className="h-2 mb-2 bg-light animate-pulse"></div>
                                    <div className="h-2 mb-2 bg-light animate-pulse"></div>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-3 flex gap-2">
                            <span className="badge rounded-1 badge-light h-4 w-full"></span>
                            <span className="badge rounded-1 badge-light h-4 w-full"></span>
                        </div>
                    </Card>)
                break;
            case "app":
                simpleCard = (
                    <div key={"card" + i} title="" className="card text-center">
                        <div className="card-body flex justify-between items-center">
                            <div className="h-12 w-12 bg-soft-secondary animate-pulse"></div>
                            <div className="text-right w-full">
                                <div className="w-6/12 ml-auto bg-light p-1 my1 animate-pulse h-3"></div>
                                <div className="w-8/12 ml-auto bg-light p-1 my-1 animate-pulse"></div>
                                <div className="w-9/12 ml-auto bg-light p-1 my-1 animate-pulse"></div>
                            </div>
                        </div>
                    </div>
                );
                break;
            default:    
                simpleCard = <></>
        }
        cards.push(simpleCard);
    }
    return <>
        {cards}
    </>
}