import { cutString } from "../../utils/helpers";

interface CardAppInterface {
  name: string;
  logoUrl: string;
  description: string;
  link: string;
}

export default function CardApp({
  name,
  logoUrl,
  description,
  link,
}: CardAppInterface) {
  /**
   * UI
   */
  return (
    <div className="card-app card hover:scale-90 transition-transform delay-100 rounded-md  hover:bg-opacity-5 hover:bg-blue-400 wave-effect">
      <a href={link} target="_blank" rel="noreferrer">
        <div className="card-body flex justify-between items-center">
          <img src={logoUrl} className="h-12 w-12 object-cover" alt={name} />
          <div className="text-right">
            <h1 className="lg:text-1xl text-1xl font-bold text-primary">
              {name}
            </h1>
            <p style={{ fontSize: 12 }} title={description}>
              {cutString(description, 40)}
            </p>
          </div>
        </div>
      </a>
    </div>
  );
}
