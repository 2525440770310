import Swal from "sweetalert2";

export const swal = Swal.mixin({
  customClass: {
    confirmButton: "mr-2 p-4 bg-soft-primary rounded",
    cancelButton: "ml-2 p-4 bg-soft-danger rounded",
  },
  buttonsStyling: false,
});

export enum LayoutType {
  horizontal = "horizontal",
  vertical = "vertical",
}

export enum LangType {
  fr = "fr",
  en = "en",
}

export enum FormActionType {
  ADD = "add",
  EDIT = "edit",
  DETAIL = "detail",
}

export enum HTTPStatusResponse {
  OK = "OK",
  ERROR = "ERROR",
}

export enum UserRoles {
  ADMIN = "ADMIN",
  USER = "USER",
  NEXAH = "NEXAH",
}

export type RequestType = "post" | "get" | "put" | "delete" | "patch";

export const FIRST_PAGE_VALUE = 1;
export const PER_PAGE_VALUE = 25;
export const NUMBERS_OF_ROWS_LOADER = 5;
export const HOME_PAGE = "/home";
export const SSO_ENABLE = false;
export const APP_SLUG = "auth";
export const CRYPTO_SECRET = "fyquhKeFtUSaRD0R5SU=";
