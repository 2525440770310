import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @ts-ignore
import { AddButton, SubmitButton } from "../../components/button/CustomButton";
// @ts-ignore
import CardTable from "../../components/card/CardTable";
// @ts-ignore
import { InputPhone } from "../../components/form/Input";
// @ts-ignore
import { Select2, Select2Tag } from "../../components/form/Select";
// @ts-ignore
import ModalForm from "../../components/modal/ModalForm";
// @ts-ignore
import { ColumnInterface } from "../../components/table/typings";
import { APP_API_URL } from "../../config/api";
import { HTTPStatusResponse, FormActionType, swal } from "../../config/global";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { useMutateWithInvalidateQueries } from "../../hooks/useMutations";
import { useQueries } from "../../hooks/useQueries";
import { useParams } from "react-router-dom";
import moment from "moment";
// @ts-ignore
import { ItemDropdownInterface } from "../../components/dropdown/typings";
// @ts-ignore
import Dropdown from "../../components/dropdown/Dropdown";
// @ts-ignore
import Drawer from "../../components/layout/Drawer";
// @ts-ignore
import Table from "../../components/table/Table";
// @ts-ignore
import { BadgeRounded } from "../../components/badge/Badge";
import { BiCustomize, BiTrash } from "react-icons/bi";
import { ImSpinner2 } from "react-icons/im";
// @ts-ignore
import { Form } from "../../components/form/Form";
// @ts-ignore
import SimpleButton from "../../components/button/SimpleButton";

export default function SingleOrganizationUserPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    setActionForm,
    titleForm,
    setTitleForm,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "users-org", title: "Utilisateurs" });
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openFormAddAppToUser, setOpenFormAddAppToUser] =
    useState<boolean>(false);
  const [rolesApp, setRolesApp] = useState<Array<Object>>([]);
  const [currentAppId, setCurrentAppId] = useState<number>();

  const params = useParams();
  const orgId = parseInt(params.id ? params.id.toString() : "");
  const queryKey = [
    `${baseApiUrl}`,
    pagination.page,
    pagination.perPage,
    params.id,
  ];

  /**
   * Form
   */
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: registerAddAppToUser,
    reset: resetAddAppToUser,
    control: controlAddAppToUser,
    handleSubmit: handleSubmitAddAppToUser,
    watch,
    formState: { errors: errorsAddAppToUser },
  } = useForm();
  const watchApp = watch("appId");

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}/${orgId}?pageNo=${pagination.page}&pageSize=${pagination.perPage}`,
    queryKey
  );

  const {
    data: appsUser,
    isLoading: appsUserIsLoading,
    refetch: refetchAppUser,
  } = useQueries(
    APP_API_URL + `user-apps/${orgId}/${currentElement.uuid}`,
    ["user-app", orgId, currentElement?.uuid],
    {
      enabled: false,
    }
  );

  const { data: roles, refetch: refetchRole } = useQueries(
    APP_API_URL + `role-list`,
    ["role-list"],
    {
      enabled: false,
    }
  );

  const { data: applications, refetch: refetchApplication } = useQueries(
    APP_API_URL + `org-app-list/${orgId}`,
    ["org-app-list", orgId],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: mutateInviteUser, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `user-invite`,
      "post",
      queryKey
    );

  const { mutateAsync: mutateAddAppToUser, isLoading: addAppToUserIsLoading } =
    useMutateWithInvalidateQueries(APP_API_URL + `user-add-app`, "post", [
      "user-app",
      orgId,
      currentElement?.uuid,
    ]);

  const {
    mutateAsync: mutateRemoveAppToUser,
    isLoading: removeAppToUserIsLoading,
  } = useMutateWithInvalidateQueries(APP_API_URL + `user-remove-app`, "post", [
    "user-app",
    orgId,
    currentElement?.uuid,
  ]);

  /**
   * Functions
   */
  const handleSubmitAddForm = (values: any) => {
    mutateInviteUser({
      ...values,
      username: values.username.value,
      roleId: values.roleId.id,
      orgId: orgId,
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        reset();
        setOpenModal(false);
        toast.success("Utilisateur ajoutée");
      }
    });
  };

  const handleSubmitAddAppForm = (values: any) => {
    const roles = values.roles.map((role: any) => role.id);
    mutateAddAppToUser({
      roles: roles,
      appId: values.appId.id,
      orgId: orgId,
      uuid: currentElement.uuid,
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        refetchAppUser();
        handleCloseAddAppToUser();
        toast.success("Application ajoutée");
      }
    });
  };

  const handleRemoveAppToUser = (element: any) => {
    setCurrentAppId(element.application.id);
    swal
      .fire({
        text: "Confirmer la suppression de l'application!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirmer",
        cancelButtonText: "Annuler",
        allowOutsideClick: false,
        width: 400,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          mutateRemoveAppToUser({
            appId: element.application.id,
            orgId: orgId,
            uuid: currentElement.uuid,
          }).then((response: any) => {
            if (response?.data?.status === HTTPStatusResponse.OK) {
              toast.success("L'application a été supprimée!");
              refetchAppUser();
            }
          });
        }
      });
  };

  const handleAddElement = () => {
    setTitleForm("Inviter un utilisateur");
    setActionForm(FormActionType.ADD);
    setCurrentElement(0);
    setOpenModal(true);
    reset();
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
    refetchAppUser();
    handleCloseAddAppToUser();
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    resetAddAppToUser();
  };

  const handleAddAppToUser = () => {
    setOpenFormAddAppToUser(true);
  };

  const handleCloseAddAppToUser = () => {
    setOpenFormAddAppToUser(false);
    resetAddAppToUser();
  };

  const handleCancelForm = () => {
    reset();
    setCurrentElement(0);
    setOpenModal(false);
  };

  /**
   * Users Dropdown options
   */
  const usersItems: ItemDropdownInterface[] = [
    {
      onClick: handleOpenDrawer,
      children: () => (
        <>
          <BiCustomize className="mr-2" />{" "}
          <span>Applications</span>
        </>
      ),
    },
  ];

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Nom",
      key: "firstName",
      dataIndex: "firstName",
    },
    {
      title: "Prénom",
      key: "lastName",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
    {
      title: "Nom d'utilisateur",
      key: "username",
      dataIndex: "username",
    },
    {
      title: "Téléphone",
      key: "phone",
      dataIndex: "phone",
    },
    {
      title: "Date de création",
      key: "date",
      render: (element: any) => (
        <>{element.date ? moment(element.date).subtract("hour", 1).format("YYYY-MM-DD HH:mm:ss") : ""}</>
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          {/* <LinkButton title="Applications" onClick={() => handleOpenDrawer(element)}><BoxIcons icon={"customize"} /></LinkButton> */}
          <Dropdown
            dataToItem={element}
            buttonChildren={"Options"}
            onOpen={() => setCurrentElement(element)}
            buttonClassName="bg-soft-primary"
            items={usersItems}
          />
        </div>
      ),
    },
  ];
  const columnsAppsUser: ColumnInterface[] = [
    {
      title: "Image",
      key: "logoUrl",
      render: (element: any) => (
        <img
          src={element.application.logo_url}
          className="h-8 w-8 object-cover"
          alt={element.application.name}
        />
      ),
    },
    {
      title: "Nom",
      key: "name",
      render: (element: any) => <>{element.application.name}</>,
    },
    {
      title: "Roles",
      key: "roles",
      render: (element: any) =>
        element.roles.map((role: any) => (
          <BadgeRounded
            key={role.id}
            text={role.name}
            className="mr-1 bg-secondary"
          />
        )),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      key: "action",
      className: "text-center",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          {removeAppToUserIsLoading &&
          currentAppId === element.application.id ? (
            <ImSpinner2 className="animate-spin text-red-500" />
          ) : (
            <SimpleButton
              className="text-red-500"
              title="Supprimer"
              onClick={() => handleRemoveAppToUser(element)}
            >
              <BiTrash />
            </SimpleButton>
          )}
        </div>
      ),
    },
  ];

  /**
   * Effect
   */

  useEffect(() => {
    refetchRole();
    refetchApplication();
  }, []);

  useEffect(() => {
    if (watchApp) setRolesApp(watchApp.roles);
  }, [watchApp]);

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[
          <AddButton
            key={"add"}
            value={"Inviter"}
            onClick={handleAddElement}
          />,
        ]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <Select2Tag
              control={control}
              placeholder={"Saisir l'adresse email"}
              label={"Adresse email"}
              register={register}
              error={errors?.username}
              name={"username"}
              textKeyItem={"name"}
              valueKeyItem={"id"}
            />
          </div>
          <div>
            <Select2
              items={roles?.data.data}
              control={control}
              placeholder={"Sélectionner le role"}
              label={"Role"}
              register={register}
              error={errors?.roleId}
              name={"roleId"}
              textKeyItem={"name"}
              valueKeyItem={"id"}
            />
          </div>
          <div>
            <InputPhone
              placeholder="Saisir le numéro de téléphone"
              label="Téléphone"
              name={"phone"}
              register={register}
              error={errors?.phone}
              options={{
                required: false,
              }}
            />
          </div>
        </div>
      </ModalForm>
      <Drawer
        open={openDrawer}
        title={`Applications de ${
          currentElement?.lastName + " " + currentElement?.firstName
        }`}
        isLoading={appsUserIsLoading}
        setOpen={handleCloseDrawer}
      >
        <AddButton
          value={openFormAddAppToUser ? "Annuler" : "Ajouter"}
          onClick={
            openFormAddAppToUser ? handleCloseAddAppToUser : handleAddAppToUser
          }
          className="ml-auto mb-2"
        />
        {openFormAddAppToUser && (
          <Form
            loading={addAppToUserIsLoading}
            onSubmit={handleSubmitAddAppToUser(handleSubmitAddAppForm)}
            className="transition-all ease-in-out  border-2 border-gray-300 p-2 mb-4"
          >
            <div className={"grid grid-cols-1 gap-4"}>
              <div>
                <Select2
                  items={applications?.data.data}
                  control={controlAddAppToUser}
                  placeholder={"Sélectionner l'application"}
                  label={"Application"}
                  register={registerAddAppToUser}
                  error={errorsAddAppToUser?.appId}
                  name={"appId"}
                  textKeyItem={"name"}
                  valueKeyItem={"id"}
                />
              </div>
              <div>
                <Select2
                  multiple
                  items={rolesApp}
                  control={controlAddAppToUser}
                  placeholder={"Sélectionner le role"}
                  label={"Role"}
                  register={registerAddAppToUser}
                  error={errorsAddAppToUser?.roles}
                  name={"roles"}
                  textKeyItem={"name"}
                  valueKeyItem={"id"}
                />
              </div>
              <SubmitButton
                loading={addAppToUserIsLoading}
                className="mx-auto mt-2"
              />
            </div>
          </Form>
        )}
        <Table
          tableClassName="text-left"
          columns={columnsAppsUser}
          loading={appsUserIsLoading}
          datas={appsUser?.data.data}
        />
      </Drawer>
    </>
  );
}
