import { UserRoles } from "./global";
import {
  BiBuilding,
  BiCog,
  BiConversation,
  BiFlag,
  BiGridAlt,
  BiHome,
  BiNotification,
  BiSlider,
  BiUser,
} from "react-icons/bi";

export interface MenuInterface {
  icon: React.FC;
  title: string;
  link: string;
  pathname: string;
  roles?: UserRoles[];
  subMenus?: MenuInterface[];
}

export const MENUS: MenuInterface[] = [
  {
    icon: BiHome,
    title: "menu:home",
    link: "/home",
    pathname: "home",
  },
  {
    icon: BiGridAlt,
    title: "menu:application",
    link: "/application",
    pathname: "application",
    roles: [UserRoles.NEXAH],
  },
  {
    icon: BiBuilding,
    title: "menu:organization",
    link: "/organization",
    pathname: "organization",
    roles: [UserRoles.NEXAH],
  },
  {
    icon: BiUser,
    title: "menu:users",
    link: "/user",
    pathname: "user",
    roles: [UserRoles.NEXAH],
  },
  {
    icon: BiCog,
    title: "Configuration",
    link: "#",
    pathname: "#",
    roles: [UserRoles.NEXAH],
    subMenus: [
      {
        icon: BiNotification,
        title: "Type de SMS",
        link: "/type-sms",
        pathname: "type-sms",
        roles: [UserRoles.ADMIN],
      },
      {
        icon: BiConversation,
        title: "Model de SMS",
        link: "/model-sms",
        pathname: "model-sms",
        roles: [UserRoles.ADMIN],
      },
      {
        icon: BiSlider,
        title: "Roles",
        link: "/role",
        pathname: "role",
        roles: [UserRoles.ADMIN],
      },
      {
        icon: BiFlag,
        title: "Langues",
        link: "/language",
        pathname: "language",
        roles: [UserRoles.ADMIN],
      },
    ],
  },
];
