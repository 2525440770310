import CardApp from "./CardApp";
import CardSkeleton from "../../components/skeleton/CardSkeleton";
import { APP_API_URL } from "../../config/api";
import { useQueries } from "../../hooks/useQueries";
import { APP_SLUG } from "../../config/global";

function AppPage() {
  /**
   * Query
   */
  const { data: apps, isLoading } = useQueries(
    APP_API_URL + `auth-apps/${APP_SLUG}`,
    ["auth-apps"]
  );

  /**
   * UI
   */
  return (
    <>
      <div className="page-title-box flex justify-between">
        <h4 className="mb-sm-0 font-size-18">Applications</h4>
      </div>
      <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
        {!isLoading ? (
          apps?.data.data.map((app: any, index: number) => {
            return (
              <CardApp
                key={"app" + index}
                description={app.description}
                logoUrl={app.logoUrl}
                name={app.name}
                link={`/connect/${app.slug}`}
              />
            );
          })
        ) : (
          <CardSkeleton type="app" numberOfCard={10} />
        )}
      </div>
      {apps?.data.data.length === 0 && <div>Liste vide</div>}
    </>
  );
}

export default AppPage;
