import { Link, useNavigate } from "react-router-dom";
import { AUTH_API_URL } from "../../config/api";
import { useAuth } from "../../hooks/useAuth";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ClientJS } from "clientjs";
import { SubmitButton } from "../../components/button/CustomButton";
import { InputEmail, InputPassword } from "../../components/form/Input";
import { Form } from "../../components/form/Form";
import { useMutate } from "../../hooks/useMutations";
import logo from "../../assets/images/logo.png";
import { APP_SLUG, HOME_PAGE, HTTPStatusResponse } from "../../config/global";
import AuthHeader from "./AuthHeader";
import LoaderBarWrapper from "../../components/loader/LoaderBarWrapper";
import { useState } from "react";
import { BiLockAlt } from "react-icons/bi";

export default function SignInPage() {
  /**
   * Hooks
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { mutateAsync: login, isLoading } = useMutate(
    AUTH_API_URL + `auth/token`
  );
  const [userDataIsLoading, setUserDataIsLoading] = useState(false);
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const fingerprint = new ClientJS().getFingerprint();

  /**
   * Functions
   */
  const handleSubmitForm = (datas: any) => {
    let result = login({
      ...datas,
      appSlug: APP_SLUG,
      deviceSecret: fingerprint,
    });
    result.then((r: any) => {
      if (r) {
        if (r.data.status === HTTPStatusResponse.OK) {
          setUserDataIsLoading(true);
          const toast_id = toast.loading("Chargement des données en cours....");
          fetch(AUTH_API_URL + `user-detail/${APP_SLUG}`, {
            headers: {
              Authorization: "Bearer " + r.data.accessToken,
              "Content-type": "application/json",
            },
            method: "GET",
          })
            .then((response: any) => {
              return response.json();
            })
            .then((detailResponse: any) => {
              if (detailResponse.status === HTTPStatusResponse.OK) {
                toast.update(toast_id, {
                  render: "Authentification Réussie",
                  type: "success",
                  isLoading: false,
                  autoClose: 3000,
                });
                signIn(
                  {
                    user: {
                      firstName: detailResponse.data.user.firstName,
                      username: detailResponse.data.user.username,
                      lastName: detailResponse.data.user.lastName,
                      email: detailResponse.data.user.email,
                      phone: detailResponse.data.user.phone,
                      orgId: detailResponse.data.orgId,
                      uuid: detailResponse.data.user.uuid,
                      authorized: r.data.authorized,
                      accessToken: r.data.accessToken,
                    },
                    roles: detailResponse.data.roles,
                  },
                  r.data.expireDate
                );

                const urlParams = new URLSearchParams(window.location.search);
                navigate(urlParams.get("redirect") ?? HOME_PAGE, {
                  replace: true,
                });
              } else {
                toast.update(toast_id, {
                  render:
                    "Une erreur est survenu lors du chargement des données!",
                  type: "error",
                  isLoading: false,
                  autoClose: 3000,
                });
              }
              setUserDataIsLoading(false);
            })
            .catch((reason: any) => {
              setUserDataIsLoading(false);
              toast.update(toast_id, {
                render:
                  "Une erreur est survenu lors du chargement des données!",
                type: "error",
                isLoading: false,
                autoClose: 3000,
              });
            });
        } else {
          toast.error(r.data.message);
        }
      }
    });
  };

  /**
   * UI
   */
  return (
    <>
      <div className="card shadow">
        <LoaderBarWrapper loading={isLoading}>
          <AuthHeader title="Connexion" />
          <div className="card-body pt-0">
            <div className="auth-logo sm:mt-[-20px] sm:justify-start grid mt-[-10px] justify-center">
              <Link to="/" className="auth-logo-dark">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title bg-gray-100 rounded-[50%] bg-light">
                    <img src={logo} alt="Logo Nexah" className=" h-8 w-8" />
                  </span>
                </div>
              </Link>
            </div>
            <p className="font-size-14 text-center">
              Connectez-vous à votre espace NEXAH.
            </p>
            <div className="p-2">
              <Form
                loading={userDataIsLoading}
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <div className="mb-3">
                  <InputEmail
                    placeholder={"Entrer votre email"}
                    label={"Email"}
                    labelClassName={"form-label inline-block text-primary"}
                    register={register}
                    name={"username"}
                    error={errors.username}
                  />
                </div>
                <div className="mb-5">
                  <InputPassword
                    register={register}
                    name={"password"}
                    label={"Mot de passe"}
                    labelClassName={"form-label inline-block text-primary"}
                    placeholder={"Enter votre mot de passe"}
                    error={errors.password}
                  />
                </div>
                <div>
                  <SubmitButton
                    value={"Connexion"}
                    loading={isLoading}
                    className="mx-auto w-[140px]"
                  />
                </div>
                <hr className="mt-4 mb-4" />
              </Form>
              <div className="text-center">
                <Link to="/forget-password" className="text-muted">
                  <BiLockAlt className={"mr-1"} style={{
                    display:"initial"
                  }}/> Mot de passe
                  oublié?
                </Link>
              </div>
            </div>
          </div>
        </LoaderBarWrapper>
      </div>
     {/*  <div className="mt-5 text-center">
        <div>
          <p>
            Vous n'avez pas de compte ??{" "}
            <Link to="/sign-up" className="font-bold text-primary">
              {" "}
              Créer un compte{" "}
            </Link>
          </p>
        </div>
      </div> */}
    </>
  );
}
