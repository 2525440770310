import profileImg from "../../assets/images/profile-img.png";

export default function AuthHeader({ title }: { title: string }) {
  /**
   * UI
   */
  return (
    <div className="bg-blue-500 bg-opacity-20">
      <div className="flex items-center">
        <div className="w-3/5">
          <div className="text-primary p-4">
            <h5 className="text-primary mb-2 font-size-18">{title}</h5>
          </div>
        </div>
        <div className="w-2/5 align-self-end">
          <img
            src={profileImg}
            alt=""
            className="img-fluid bg-cover card-img-header"
          />
        </div>
      </div>
    </div>
  );
}
