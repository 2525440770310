import profile from "../../assets/images/profile-img.png";
import Col from "../../components/grid/Col";
import Row from "../../components/grid/Row";
import Avatar from "../../components/avatar/Avatar";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useQueries } from "../../hooks/useQueries";
import { APP_API_URL } from "../../config/api";
import { BiBuilding, BiPhone, BiUser } from "react-icons/bi";

interface OrganizationMenuInterface {
  title: string;
  link?: string;
  active?: boolean;
  isVisible?: boolean;
}

export default function SingleOrganizationPage() {
  /**
   * Hooks
   */
  const location = useLocation();
  const pathname = location.pathname.replace("/", "");
  const params = useParams();

  /**
   * Query
   */
  const { data: organization } = useQueries(
    APP_API_URL + `organization/${params.id}`,
    ["organization-detail", params.id]
  );

  const menusAccount: OrganizationMenuInterface[] = [
    {
      title: "Compte",
    },
    {
      title: "Informations du compte",
      link: `/organization/${params.id}`,
      active: pathname === `organization/${params.id}`,
    },
    {
      title: "Applications",
      link: `/organization/${params.id}/application`,
      active: pathname === `organization/${params.id}/application`,
    },
    {
      title: "Compte Utilisateurs",
      link: `/organization/${params.id}/user`,
      active: pathname === `organization/${params.id}/user`,
    },
  ];
  return (
    <div className="md:w-5/6 w-full mx-auto">
      <div className={"grid md:grid-cols-12 grid-cols-1 gap-4"}>
        <div className={"lg:col-span-3 md:col-span-5"}>
          <div className="card">
            <div className="bg-soft-primary">
              <Row cols={2}>
                <Col>
                  <div className="text-primary p-3">
                    <h5 className="text-primary">Welcome Back !</h5>
                  </div>
                </Col>
                <Col className="flex justify-end">
                  <img src={profile} alt="" className="img-fluid h-24" />
                </Col>
              </Row>
            </div>
            <div className="card-body pt-0">
              <div>
                <div className="avatar-md profile-user-wid mb-4 mx-auto">
                  <Avatar title={organization?.data.data.name} />
                </div>
                <div>
                  <h5 className="mb-2">
                    <BiUser className="mr-2 text-primary" />{" "}
                    {organization?.data.data.name}
                  </h5>
                  <h5 className="mb-2">
                    <BiBuilding className="mr-2 text-primary" />{" "}
                    {organization?.data.data.town}
                  </h5>
                  <h5 className="mb-2">
                    <span className="mr-2 text-primary">@</span>{" "}
                    {organization?.data.data.email}
                  </h5>
                  <h5 className="mb-2">
                    <BiPhone className="mr-2 text-primary" />{" "}
                    {organization?.data.data.phone}
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body p-4">
              <div>
                <ul className="font-semibold">
                  {menusAccount
                    .filter(
                      (menu) => menu.isVisible || menu.isVisible === undefined
                    )
                    .map((menu: OrganizationMenuInterface, index: number) => {
                      if (menu.link === undefined) {
                        return (
                          <li key={`menu-account-${index}`}>
                            <p className="text-muted">{menu.title}</p>
                            <hr className="my-4" />{" "}
                          </li>
                        );
                      } else {
                        return (
                          <li
                            key={`menu-account-${index}`}
                            className={`py-2 hover:cursor-pointer ${
                              menu.active && "text-primary"
                            }`}
                          >
                            <Link to={menu.link}>
                              <i className="mdi mdi-chevron-right mr-1" />{" "}
                              {menu.title}
                            </Link>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className={"lg:col-span-9 md:col-span-7"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
