import { useState } from "react";
import AuthContext from "./AuthContext"
import { AUTH_SIGN_IN_URL } from "../../config/api";
import useStorage from "../../hooks/useStorage";

interface AuthProviderPropsInterface {
    children: React.ReactNode
}

function AuthProvider({ children }: AuthProviderPropsInterface) {
    const { addStorageUser, getStorageUser, removeStorageUser } = useStorage();
    let [user, setUser] = useState<any>(getStorageUser());
    let signIn = (user: any, expireDate: string) => {
        setUser(user)
        addStorageUser(user, expireDate)
    }
    let signOut = () => {
        setUser(null)
        removeStorageUser()
        window.location.assign(AUTH_SIGN_IN_URL)
    };
    let value = { user, signIn, signOut };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export default AuthProvider;
