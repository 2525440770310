import { Link, useLocation, useNavigate } from "react-router-dom";
import { AUTH_API_URL } from "../../config/api";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../components/button/CustomButton";
import { InputCode } from "../../components/form/Input";
import { Form } from "../../components/form/Form";
import { useMutate } from "../../hooks/useMutations";
import logo from "../../assets/images/logo.png";
import AuthHeader from "./AuthHeader";
import Redirect from "../../components/Redirect";
import LoaderBarWrapper from "../../components/loader/LoaderBarWrapper";
import { HTTPStatusResponse } from "../../config/global";
import { toast } from "react-toastify";

export default function VerifyCodePage() {
  /**
   * Hooks
   */
  const navigate = useNavigate();
  const { state } = useLocation();

  /**
   * Form
   */
  const { handleSubmit, setValue } = useForm({
    defaultValues: {
      code: "",
    },
  });
  const { isLoading, mutateAsync } = useMutate(
    AUTH_API_URL + `auth/check-code`
  );

  /**
   * Functions
   */
  const handleSubmitForm = (datas: any) => {
    let result = mutateAsync({ ...state, ...datas });
    result.then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        navigate("/reset-password", {
          state: { code: response.data.data },
        });
      } else {
        toast.error("Token invalide");
        navigate("/404", { replace: true });
      }
    });
  };

  /**
   * UI
   */
  if (state === null) return <Redirect to="/404" />;
  return (
    <>
      <div className="card shadow">
        <LoaderBarWrapper loading={isLoading}>
          <AuthHeader title="Vérifcation du code" />
          <div className="card-body pt-0">
            <div className="auth-logo sm:mt-[-20px] sm:justify-start grid mt-[-10px] justify-center">
              <Link to="/" className="auth-logo-dark">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title bg-gray-100 rounded-[50%] bg-light">
                    <img src={logo} alt="Logo Nexah" className=" h-8 w-8" />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              <h1 className="text-1xl text-center mb-5 ">
                Saisissez le code envoyé au numéro <b>{state?.phone}</b>
              </h1>
              <Form
                loading={isLoading}
                onSubmit={handleSubmit(handleSubmitForm)}
              >
                <div className="flex gap-2 justify-center">
                  <InputCode
                    name={"code"}
                    setValue={setValue}
                    numberOfElement={5}
                  />
                </div>
                <hr className="mt-4 mb-4" />
                <div>
                  <SubmitButton
                    value={"Vérifier"}
                    loading={isLoading}
                    className="mx-auto w-[140px]"
                  />
                </div>
              </Form>
            </div>
          </div>
        </LoaderBarWrapper>
      </div>
    </>
  );
}
