import { Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { AUTH_SIGN_IN_URL } from "../config/api";
import Redirect from "../components/Redirect";

export default function RequireAuth() {
  const auth = useAuth();
  const { pathname } = useLocation();
  return auth && auth.user ? (
    <Outlet />
  ) : (
    <Redirect to={`${AUTH_SIGN_IN_URL}?redirect=${pathname}`} />
  );
}
