import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @ts-ignore
import { AddButton, EditButton } from "../components/button/CustomButton";
// @ts-ignore
import CardTable from "../components/card/CardTable";
// @ts-ignore
import { InputText, TextArea } from "../components/form/Input";
// @ts-ignore
import ModalForm from "../components/modal/ModalForm";
import { FormActionType, HTTPStatusResponse } from "../config/global";
// @ts-ignore
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";
// @ts-ignore
import { BadgeRounded } from "../components/badge/Badge";
import { useEffect } from "react";
// @ts-ignore
import { Select2 } from "../components/form/Select";

export default function ModelSMSPage() {
  /**
   * Hooks
   * */
  const {
    baseApiUrl,
    title,
    queryKey,
    actionForm,
    setActionForm,
    titleForm,
    setTitleForm,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "modelsms", title: "Models SMS" });

  /**
   * Form
   */
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}?pageNo=${pagination.page}&pageSize=${pagination.perPage}`,
    queryKey
  );

  const { data: languages, refetch: refetchLanguage } = useQueries(
    APP_API_URL + `language-list`,
    ["language-list"],
    {
      enabled: false,
    }
  );

  const { data: typesSMS, refetch: refecthTypeSMS } = useQueries(
    APP_API_URL + `typesms-list`,
    ["typesms-list"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: updateData, isLoading: updateIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "post",
      queryKey
    );

  /**
   * Function
   */
  const handleSubmitAddForm = (values: any) => {
    storeData({
      ...values,
      typeSMSId: values.typeSMSId.id,
      languageId: values.languageId.id,
    }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        reset();
        toast.success("Model de SMS ajoutée !");
        setOpenModal(false);
      }
    });
  };

  const handleSubmitEditForm = (values: any) => {
    updateData({
      ...values,
      typeSMSId: values.typeSMSId.id,
      languageId: values.languageId.id,
    }).then((response: any) => {
      if (response && response.data.status === HTTPStatusResponse.OK) {
        reset();
        toast.success("Model de SMS modifiée");
        setOpenModal(false);
        setCurrentElement(0);
      }
    });
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Ajouter un model de SMS");
    setActionForm(FormActionType.ADD);
    setOpenModal(true);
    reset();
  };

  const handleEditElement = (element: any) => {
    setTitleForm("Modifier une model de SMS");
    setActionForm(FormActionType.EDIT);
    setValue("title", element.title);
    setValue("message", element.message);
    setValue("languageId", element.language);
    setValue("typeSMSId", element.typeSms);
    setValue("userId", element.user);
    setCurrentElement(element.id);
    setOpenModal(true);
  };

  /**
   * Effect
   */
  useEffect(() => {
    refetchLanguage();
    refecthTypeSMS();
  }, [refetchLanguage, refecthTypeSMS]);

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Title",
      key: "title",
      dataIndex: "title",
    },
    {
      title: "Message",
      key: "message",
      dataIndex: "message",
    },
    {
      title: "Langue",
      key: "languageId",
      render: (element: any) => (
        <BadgeRounded
          className="badge-soft-secondary"
          text={element.language.name}
        />
      ),
    },
    {
      title: "Type de SMS",
      key: "typeSMSId",
      render: (element: any) => (
        <BadgeRounded
          className="badge-soft-secondary"
          text={element.typeSms.type}
        />
      ),
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          <EditButton onClick={() => handleEditElement(element)} />
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        extras={[<AddButton key={"add"} onClick={handleAddElement} />]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={
          actionForm === FormActionType.ADD
            ? storeDataIsLoading
            : updateIsLoading
        }
        onClose={handleCancelForm}
        onConfirm={handleSubmit(
          actionForm === FormActionType.ADD
            ? handleSubmitAddForm
            : handleSubmitEditForm
        )}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4"}>
          <div>
            <InputText
              placeholder={"Entrer le titre"}
              label={"Titre"}
              register={register}
              error={errors?.title}
              name={"title"}
            />
          </div>
          <div>
            <TextArea
              placeholder={"Entrer le message"}
              label={"Message"}
              register={register}
              error={errors?.message}
              name={"message"}
            />
          </div>
          <div>
            <Select2
              control={control}
              placeholder={"Selection de la langue"}
              label={"Langue"}
              register={register}
              error={errors?.languageId}
              rules={{
                required: false,
              }}
              name={"languageId"}
              textKeyItem={"name"}
              valueKeyItem={"id"}
              items={languages?.data?.data}
            />
          </div>
          <div>
            <Select2
              control={control}
              placeholder={"Selection du type de SMS"}
              label={"Type de SMS"}
              register={register}
              error={errors?.typeSMSId}
              rules={{
                required: false,
              }}
              name={"typeSMSId"}
              textKeyItem={"type"}
              valueKeyItem={"id"}
              items={typesSMS?.data?.data}
            />
          </div>
        </div>
      </ModalForm>
    </>
  );
}
