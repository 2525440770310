import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
// @ts-ignore
import { AddButton, EditButton } from "../components/button/CustomButton";
// @ts-ignore
import CardTable from "../components/card/CardTable";
// @ts-ignore
import { InputText } from "../components/form/Input";
// @ts-ignore
import ModalForm from "../components/modal/ModalForm";
import { FormActionType } from "../config/global";
// @ts-ignore
import { ColumnInterface } from "../components/table/typings";
import { useQueries } from "../hooks/useQueries";
import { APP_API_URL } from "../config/api";
import { useMutateWithInvalidateQueries } from "../hooks/useMutations";
import useHeaderFunctionPage from "../hooks/useHeaderFunctionPage";

export default function RolePage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    actionForm,
    setActionForm,
    titleForm,
    setTitleForm,
    currentElement,
    setCurrentElement,
    openModal,
    setOpenModal,
    pagination,
    setPagination,
  } = useHeaderFunctionPage({ baseApiUrl: "role", title: "Roles" });

  /**
   * Form
   */
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: datas, isLoading } = useQueries(
    APP_API_URL +
      `${baseApiUrl}s?pageNo=${pagination.page}&pageSize=${pagination.perPage}`,
    queryKey
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  const { mutateAsync: updateData, isLoading: updateIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}/${currentElement}`,
      "post",
      queryKey
    );

  /**
   * Function
   */
  const handleSubmitAddForm = (values: any) => {
    storeData(values).then((response: any) => {
      if (response && response.data.status === "OK") {
        reset();
        toast.success("Role ajouté !");
        setOpenModal(false);
      }
    });
  };

  const handleSubmitEditForm = (values: any) => {
    updateData(values).then((response: any) => {
      if (response && response.data.status === "OK") {
        reset();
        toast.success("Role modifié");
        setOpenModal(false);
        setCurrentElement(0);
      }
    });
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  const handleAddElement = () => {
    setTitleForm("Ajouter un role");
    setActionForm(FormActionType.ADD);
    setOpenModal(true);
    reset();
  };

  const handleEditElement = (element: any) => {
    setTitleForm("Modifier le role");
    setActionForm(FormActionType.EDIT);
    setValue("name", element.name);
    setCurrentElement(element.id);
    setOpenModal(true);
  };

  /**
   * Columns Table
   */
  const columns: ColumnInterface[] = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
    },
    {
      title: <span style={{ textAlign: "center" }}>#</span>,
      dataIndex: "actions",
      key: "actions",
      render: (element: any) => (
        <div className="flex justify-center gap-2">
          <EditButton onClick={() => handleEditElement(element)} />
        </div>
      ),
    },
  ];

  /**
   * UI
   */
  return (
    <>
      <CardTable
        className="mx-auto md:w-2/4 lg:w-1/4"
        extras={[<AddButton key={"add"} onClick={handleAddElement} />]}
        columns={columns}
        title={title}
        loading={isLoading}
        datas={datas?.data}
        pagination={pagination}
        setPagination={setPagination}
        tableClassName="text-center"
      />
      <ModalForm
        title={titleForm}
        isLoading={
          actionForm === FormActionType.ADD
            ? storeDataIsLoading
            : updateIsLoading
        }
        onClose={handleCancelForm}
        onConfirm={handleSubmit(
          actionForm === FormActionType.ADD
            ? handleSubmitAddForm
            : handleSubmitEditForm
        )}
        open={openModal}
      >
        <div className={"grid grid-cols-1"}>
          <div>
            <InputText
              placeholder={"Entrer le nom"}
              label={"Name"}
              register={register}
              error={errors?.name}
              name={"name"}
            />
          </div>
        </div>
      </ModalForm>
    </>
  );
}
