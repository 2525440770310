import { useMutate } from "../../hooks/useMutations";
import { useNavigate, useParams } from "react-router-dom";
import { AUTH_API_URL } from "../../config/api";
import { useEffect } from "react";
import { ClientJS } from "clientjs";
import { HTTPStatusResponse } from "../../config/global";
import FullLoaderImg from "../../components/loader/FullLoaderImg";

export default function SignInIntoAppPage() {
  /**
   * Hooks
   */
  const { appSlug } = useParams();
  const fingerprint = new ClientJS().getFingerprint();
  const navigate = useNavigate();

  /**
   * Mutations
   */
  const { mutateAsync } = useMutate(AUTH_API_URL + `access`);

  /**
   * Functions
   */
  const loginIntoApp = () => {
    mutateAsync({ appSlug: appSlug, deviceSecret: fingerprint }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        window.location.assign(response?.data.data);
      } else {
        navigate("/403", {
          state: {
            message: "Vous n'avez pas d'accès à cette application",
          },
        });
      }
    });
  };

  /**
   * Effect
   */
  useEffect(() => {
    loginIntoApp();
  }, []);

  /**
   * UI
   */
  return <FullLoaderImg text="Connexion a une application en cours..." />;
}
