import { Fragment, useEffect } from "react"
import Loader from "../loader/Loader";
import { CloseButton } from "../button/CustomButton";
import { Transition } from "@headlessui/react";

interface DrawerInterface {
    open: boolean,
    title: string,
    className?: string,
    isLoading: boolean,
    setOpen: () => void,
    children: React.ReactNode
}

export default function Drawer({ open, isLoading = true, title, setOpen, children, className = "sm:w-[500px!important]" }: DrawerInterface) {
    //Functions
    const handleOpenMenu = (enabled: boolean) => {
        if (enabled) {
            document.body.className = document.body.className.concat(" right-bar-enabled")
        } else {
            document.body.className = document.body.className.replace("right-bar-enabled", "");
        }
    }
    useEffect(() => {
        handleOpenMenu(open)
    }, [open])
    return (
        <>
            <Transition appear show={open}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed z-[999] inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
            </Transition>
            {open && (
                <div className={`right-bar ${className} w-[100%!important]`}>
                    <div data-simplebar className="h-full overflow-y-au">
                        <div className="rightbar-title flex items-center px-3 py-4">
                            <h5 className="m-0">{title}</h5>
                            <CloseButton onClick={setOpen} />
                        </div>
                        <hr className="mt-0" />

                        {isLoading ? (<div className="h-screen w-full flex justify-center items-center">
                            <Loader type="ball-move" />
                        </div>) : <div className="px-3 py-4 h-screen overflow-x-auto">{children}</div>}
                    </div>
                </div>
            )}
        </>
    )
}