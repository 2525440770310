import { Link, useNavigate } from "react-router-dom";
import { AUTH_API_URL } from "../../config/api";
import { SubmitButton } from "../../components/button/CustomButton";
import {
  InputEmail,
  InputPassword,
  InputText,
} from "../../components/form/Input";
import { Form } from "../../components/form/Form";
import { useMutate } from "../../hooks/useMutations";
import logo from "../../assets/images/logo.png";
import { useForm } from "react-hook-form";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import AuthHeader from "./AuthHeader";

export default function SignUpPage() {
  /**
   * Hooks
   */
  const navigate = useNavigate();

  /**
   * Form
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Mutation
   */
  const { mutateAsync: signUp, isLoading } = useMutate(
    AUTH_API_URL + `api/auth/sign-up`
  );

  /**
   * Functions
   */
  const handleSubmitForm = (datas: any) => {
    const response = signUp(datas);
    response.then((r: any) => {
      navigate("/sign-in", { replace: true });
      //toast.success(r.data.message);
    });
  };

  /**
   * UI
   */
  return (
    <>
      <div className="card shadow">
        <AuthHeader title="Inscription" />
        <div className="card-body pt-0">
          <div className="auth-logo sm:mt-[-20px] sm:justify-start grid mt-[-10px] justify-center">
            <Link to="/" className="auth-logo-dark">
              <div className="avatar-md profile-user-wid mb-4">
                <span className="avatar-title bg-gray-100 rounded-[50%] bg-light">
                  <img src={logo} alt="Logo Nexah" className=" h-8 w-8" />
                </span>
              </div>
            </Link>
          </div>
          <p className="font-size-14 text-center mb-4">
            Inscrivez-vous gratuitement et en entrez dans l'univers NEXAH
          </p>
          <div className="p-2">
            <Form loading={isLoading} onSubmit={handleSubmit(handleSubmitForm)}>
              <Row cols={1} className="sm:grid-cols-2">
                <Col className="mb-3">
                  <InputEmail
                    placeholder={"Entrer votre email"}
                    label={"Email"}
                    labelClassName={"form-label inline-block"}
                    register={register}
                    name={"email"}
                    error={errors.email}
                  />
                </Col>
                <Col className="mb-3">
                  <InputText
                    placeholder={"Entrer votre numéro de téléphone"}
                    label={"Téléphone"}
                    labelClassName={"form-label inline-block"}
                    register={register}
                    name={"phone"}
                    error={errors.email}
                  />
                </Col>
                <Col className="mb-3">
                  <InputText
                    placeholder={"Entrer le nom de l'entreprise"}
                    label={"Entreprise"}
                    labelClassName={"form-label inline-block"}
                    register={register}
                    name={"company"}
                    error={errors.email}
                  />
                </Col>
                <Col className="mb-3">
                  <InputPassword
                    register={register}
                    name={"password"}
                    label={"Mot de passe"}
                    labelClassName={"form-label inline-block"}
                    placeholder={"Enter votre mot de passe"}
                    error={errors.password}
                  />
                </Col>
              </Row>
              <hr className="mt-4 mb-4" />
              <div className="mt-4 ">
                <SubmitButton
                  value={"S'inscrire"}
                  loading={isLoading}
                  className="mx-auto w-[140px]"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="mt-5 text-center">
        <div>
          <p>
            Vous possedez déjà un compte ??{" "}
            <Link to="/sign-in" className="fw-medium text-primary">
              {" "}
              Connectez-vous{" "}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}