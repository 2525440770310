import { Outlet } from "react-router-dom";

function MainAuth() {
    return (
        <div className="account-pages h-screen my-auto flex">
            <div className="w-full px-5">
                <div className="flex justify-content-center h-screen items-center">
                    <div className="md:w-2/4 lg:w-1/4 sm:w-3/4 w-full mx-auto">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MainAuth
