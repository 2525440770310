import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddButton } from "../../components/button/CustomButton";
import {
  InputText,
  InputPhone,
  InputEmail
} from "../../components/form/Input";
import { Select2, Select2Tag } from "../../components/form/Select";
import ModalForm from "../../components/modal/ModalForm";
import CardSkeleton from "../../components/skeleton/CardSkeleton";
import { APP_API_URL } from "../../config/api";
import { HTTPStatusResponse } from "../../config/global";
import useHeaderFunctionPage from "../../hooks/useHeaderFunctionPage";
import { useMutateWithInvalidateQueries } from "../../hooks/useMutations";
import { useQueries } from "../../hooks/useQueries";
import orgImg from "../../assets/images/orgs/org1.png";
import { Link } from "react-router-dom";
import { cutString } from "../../utils/helpers";
import { BiEnvelope, BiGlobe, BiMap, BiPhone } from "react-icons/bi";

export default function OrganizationPage() {
  /**
   * Hooks
   */
  const {
    baseApiUrl,
    title,
    queryKey,
    openModal,
    setOpenModal,
    titleForm,
    setTitleForm,
  } = useHeaderFunctionPage({
    baseApiUrl: "organization",
    title: "Organisations",
  });

  /**
   * Form
   */
  const {
    register,
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Query
   */
  const { data: organizations, isLoading } = useQueries(
    APP_API_URL + `${baseApiUrl}s`,
    queryKey
  );

  const { data: activityAreas, refetch: refacthActivityAreas } = useQueries(
    APP_API_URL + `activity-areas`,
    ["activity-areas"],
    {
      enabled: false,
    }
  );

  /**
   * Mutation
   */
  const { mutateAsync: storeData, isLoading: storeDataIsLoading } =
    useMutateWithInvalidateQueries(
      APP_API_URL + `${baseApiUrl}-store`,
      "post",
      queryKey
    );

  /**
   * Functions
   */

  const handleSubmitAddForm = (values: any) => {
    const activityAreas = values.activityAreas.map(
      (activityArea: any) => activityArea.name
    );
    const adminEmails = values.adminEmails.map((email: any) => email.value);
    console.log({
      ...values,
      activityAreas: activityAreas,
      adminEmails: adminEmails,
    });
    storeData({
      ...values,
      activityAreas: activityAreas,
      adminEmails: adminEmails,
    }).then((response: any) => {
      if (response?.data?.status === HTTPStatusResponse.OK) {
        reset();
        setOpenModal(false);
        toast.success("Application ajoutée");
      }
    });
  };

  const handleAddElement = () => {
    setTitleForm("Créer une organisation");
    setOpenModal(true);
    reset();
  };

  const handleCancelForm = () => {
    reset();
    setOpenModal(false);
  };

  /**
   * Effect
   */
  useEffect(() => {
    refacthActivityAreas();
  }, []);

  return (
    <>
      <div className="page-title-box flex justify-between">
        <h4 className="mb-sm-0 font-size-18">{title}</h4>
        <AddButton onClick={handleAddElement} />
      </div>
      <div className="grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
        {!isLoading ? (
          organizations?.data.data?.map((data: any, index: number) => {
            return (
              <Link key={"app" + index} to={`/organization/${data.organization.id}`}>
                <div className="transition-transform hover:translate-y-1.5 hover:scale-105 card h-[250px] overflow-auto">
                  <div className="card-body">
                    <img
                      src={data.organization.img ?? orgImg}
                      alt={data.organization.name}
                      className="mb-3 h-10 w-10 mx-auto"
                    />
                    <div>
                      <h5
                        className="text-[15px] mb-2"
                        title={data.organization.name}
                      >
                        <span className="text-dark font-bold">
                          {cutString(data.organization.name, 29)}
                        </span>
                      </h5>
                      <ul className="list-inline mb-0">
                        <li className="list-inline-item">
                          <p
                            className="text-muted text-[12px] mb-0"
                            title={data.organization.address}
                          >
                            <BiEnvelope className="mr-1" />
                            <span>{cutString(data.organization.email, 30)}</span>
                          </p>
                          <p className="text-muted text-[12px] mb-0">
                            <BiPhone className="mr-1" />
                            <span>{data.organization.phone}</span>
                          </p>
                          <p
                            className="text-muted text-[12px] mb-0"
                            title={data.organization.address}
                          >
                            <BiMap className="mr-1" />
                            <span>{cutString(data.organization.address, 35)}</span>
                          </p>
                          <p className="text-muted text-[12px] mb-0">
                            <BiGlobe className="mr-1" />
                            <span>{cutString(data.organization.web_site, 30)}</span>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="mt-3 flex gap-2">
                      <span className="badge rounded-1 badge-light">
                        ({data.usersCount}) Utilisateurs
                      </span>
                      <span className="badge rounded-1 badge-light">
                        ({data.appsCount}) Applications
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })
        ) : (
          <CardSkeleton type="org" numberOfCard={10} />
        )}
      </div>
      <ModalForm
        title={titleForm}
        isLoading={storeDataIsLoading}
        onClose={handleCancelForm}
        onConfirm={handleSubmit(handleSubmitAddForm)}
        open={openModal}
      >
        <div className={"grid grid-cols-1 gap-4 sm:grid-cols-2"}>
          <div>
            <InputText
              placeholder={"Entrer le nom"}
              label={"Nom"}
              register={register}
              error={errors?.name}
              name={"name"}
            />
          </div>
          <div>
            <InputPhone
              placeholder={"Entrer le téléphone"}
              label={"Téléphone"}
              register={register}
              error={errors?.phone}
              name={"phone"}
            />
          </div>
          <div className="col-span-2">
            <InputEmail
              placeholder={"Entrer l'adresse email"}
              label={"Email"}
              register={register}
              error={errors?.email}
              name={"email"}
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer la ville"}
              label={"Ville"}
              register={register}
              error={errors?.town}
              name={"town"}
            />
          </div>
          <div>
            <InputText
              placeholder={"Entrer l'adresse"}
              label={"Adresse"}
              register={register}
              error={errors?.address}
              name={"address"}
            />
          </div>
          <div className="col-span-2">
            <Select2
              multiple
              items={activityAreas?.data.data}
              control={control}
              placeholder={"Sélectionner les secteurs d'activité"}
              label={"Secteurs d'activités"}
              register={register}
              error={errors?.activityAreas}
              name={"activityAreas"}
              textKeyItem={"name"}
              valueKeyItem={"name"}
            />
          </div>
          <div className="col-span-2">
            <Select2Tag
              multiple
              textKeyItem={""}
              valueKeyItem={""}
              control={control}
              placeholder={"Saisissez les emails des administrateurs"}
              label={"Emails administrateurs"}
              register={register}
              error={errors?.adminEmails}
              name={"adminEmails"}
            />
          </div>
        </div>
      </ModalForm>
    </>
  );
}
