import { AvatarInterface } from "./typings";

export default function Avatar({ src = "", title, size = "h-16 w-16" }: AvatarInterface) {
    return (<div className={`bg-light rounded-full relative ${size} flex items-center justify-center`}>
        <span className="inline-block">{title && title[0]}</span>
        {src && (<img
            src={src}
            alt={title && title[0]}
            className="img-thumbnail rounded-full absolute inset-0"
        />)}
    </div>)
}