import { Link, useLocation, useNavigate } from "react-router-dom";
import { AUTH_API_URL } from "../../config/api";
import { useForm } from "react-hook-form";
import { SubmitButton } from "../../components/button/CustomButton";
import { InputPassword } from "../../components/form/Input";
import { Form } from "../../components/form/Form";
import { useMutate } from "../../hooks/useMutations";
import logo from "../../assets/images/logo.png";
import LoaderBarWrapper from "../../components/loader/LoaderBarWrapper";
import AuthHeader from "./AuthHeader";
import Redirect from "../../components/Redirect";
import { toast } from "react-toastify";
import { HTTPStatusResponse } from "../../config/global";
import { useState } from "react";

export default function ResetPasswordPage() {
  /**
   * Hooks
   */
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  /**
   * Forms
   */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /**
   * Mutations
   */
  const { isLoading, mutateAsync: resetPassword } = useMutate(
    AUTH_API_URL + `auth/password-reset`
  );

  /**
   * Functions
   */
  const handleSubmitForm = (datas: any) => {
    if (datas.password === datas.confirmPassword) {
      let result = resetPassword({ ...datas, code: state.code });
      result.then((response: any) => {
        if (!response || response?.data.status !== HTTPStatusResponse.OK) {
          toast.warning(
            "Le token a expiré, vous devez recommencer le processus"
          );
        } else {
          setIsSuccess(true);
        }
      });
    } else {
      toast.warning("Les mots de passe ne sont pas identiques");
    }
  };

  const handleBackToHome = () => {
    navigate("/sign-in", {
      replace: true,
    });
  };

  /** UI */
  if (state === null) return <Redirect to="/404" />;
  return (
    <>
      <div className="card shadow">
        <LoaderBarWrapper loading={isLoading}>
          <AuthHeader title="Mise à jour du mot de passe" />
          <div className="card-body pt-0">
            <div className="auth-logo sm:mt-[-20px] sm:justify-start grid mt-[-10px] justify-center">
              <Link to="/" className="auth-logo-dark">
                <div className="avatar-md profile-user-wid mb-4">
                  <span className="avatar-title bg-gray-100 rounded-[50%] bg-light">
                    <img src={logo} alt="Logo Nexah" className=" h-8 w-8" />
                  </span>
                </div>
              </Link>
            </div>
            <div className="p-2">
              {!isSuccess ? (
                <Form
                  loading={isLoading}
                  onSubmit={handleSubmit(handleSubmitForm)}
                >
                  <div className="mb-3">
                    <InputPassword
                      placeholder={"Entrer le nouveau mot de passe"}
                      label={"Nouveau mot de passe"}
                      labelClassName={"form-label inline-block"}
                      register={register}
                      name={"password"}
                      error={errors.password}
                    />
                  </div>
                  <div className="mb-3">
                    <InputPassword
                      placeholder={"Confirmation du nouveau mot de passe"}
                      label={"Confirmation du mot de passe"}
                      labelClassName={"form-label inline-block"}
                      register={register}
                      name={"confirmPassword"}
                      error={errors.confirmPassword}
                    />
                  </div>
                  <hr className="mt-4 mb-4" />
                  <div>
                    <SubmitButton
                      value={"Confirmer"}
                      loading={isLoading}
                      className="mx-auto w-[140px]"
                    />
                  </div>
                </Form>
              ) : (
                <div>
                  <h1 className="text-3xl font-bold text-center mb-5 text-primary">
                    Success!
                  </h1>
                  <p className="text-center text-2xl mb-2">
                    Votre compte a été réinitialisé,{" "}
                    <span
                      className="text-primary hover:cursor-pointer"
                      onClick={handleBackToHome}
                    >
                      Connectez-vous
                    </span>{" "}
                    à l'aide de vos nouveaux identifiants
                  </p>
                </div>
              )}
            </div>
          </div>
        </LoaderBarWrapper>
      </div>
    </>
  );
}
